@config "../tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // display: flex;
  // align-items: center;
  justify-content: center;
  // height: 100%;
  // background-color: rgb(17, 24, 39);
  background-color: transparent;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid rgb(239, 68, 68); /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
}

.inputrange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: rgb(239, 68, 68);
  cursor: pointer;
  border-radius: 50%;
}